import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
	textField : {
		'& input' : {
			fontSize : '0.9rem !important'
		}
	},
	label : {
		fontSize : '0.7rem',
		color    : 'rgba(0, 0, 0, 0.54)'
	}
}));

export default function DatePickerMonthDay({
	dateMonthDay,
	setDateMonthDay,
	currentDay
})
{
	const classes = useStyles();

	const handleDateChange = (e) =>
	{
		if (e.target?.value)
		{
			setDateMonthDay(e.target.value);
		}
	};

	return (
		<Box>
			<Typography className={classes.label}>
				月&nbsp;/&nbsp;日
			</Typography>
			<TextField
				type='date'
				value={dateMonthDay}
				max={currentDay}
				onChange={(e) => handleDateChange(e)}
				classes={{
					root : classes.textField
				}}
			/>
		</Box>
	);
}

DatePickerMonthDay.propTypes = {
	dateMonthDay    : PropTypes.string.isRequired,
	setDateMonthDay : PropTypes.func.isRequired,
	currentDay      : PropTypes.string.isRequired
};
