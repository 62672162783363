// React
import React, { useState, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// firestore
import { firestore } from '../../../lib/firebase';

// confirmDialog
import ConfirmDialog from '../Common/ConfirmDialog';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

// dayjs
import dayjs from 'dayjs';

// downLoadDate
const today = new Date();
const todayValues = {
	year  : today.getFullYear(),
	month : today.getMonth() + 1,
	day   : today.getDate()
};

// years from service start
const yearsToToday = [];

for (let y=2022; y <= todayValues.year; y++)
{
	yearsToToday.push(y);
}

// create days array from end of month day
const createDaysAr = (endOfMonth) =>
{
	const daysAr = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
		15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28 ];

	for (let d=29; d <= endOfMonth; d++)
	{
		daysAr.push(d);
	}

	return daysAr;
};

// final day of this month
const initialEndOfMonth =
dayjs()
	.endOf('month')
	.get('date');

// days of this month
const initialDays = createDaysAr(initialEndOfMonth);

const styles = () =>
	({
		exportBtn : {
			fontSize     : '1rem',
			background   : 'white',
			color        : 'var(--text-color)',
			border       : '1px solid var(--text-color)',
			boxShadow    : 'none',
			marginBottom : '3px'
		},
		firstExportBtn : {
			marginLeft : '2rem'
		},
		secondExportBtn	: {
			marginLeft : '1rem'
		},
		selectItemBox : {
			fontSize       : '1.5rem',
			marginBottom   : '1rem',
			color          : 'var(--text-color)',
			display        : 'flex',
			justifyContent : 'start',
			height         : '2rem'
		},
		selectItemInfo : {
			display        : 'flex',
			justifyContent : 'start'
		},
		selectMonthDay : {
			marginLeft : '0.25rem'
		},
		separator : {
			marginLeft  : '0.4rem',
			marginRight : '0.4rem',
			lineHeight  : '1.75rem'
		},
		selectItemTitle : {
			display       : 'block',
			fontSize      : '1.1rem',
			paddingBottom : '4px',
			color         : '#999898'
		},
		selectItem : {
			color : '#888'
		},
		typeSelect : {
			minWidth : '142.4px'
		},
		checkBox : {
			color                   : 'var(--text-color)',
			fontSize                : '0.8rem',
			'& .MuiTypography-root' : {
				fontSize   : '0.8rem',
				fontWeight : 300
			}
		},
		dataSelect :
		{
			fontSize : '0.8rem',
			padding  : '0 2%'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiOutlinedInput : {
			inputMarginDense : {
				paddingTop    : '5px',
				paddingBottom : '5px'
			}
		}
	}
});

const AdminDateSelectDownload = ({
	classes,
	page,
	selected,
	selectedPortal,
	selectedServiceAccount,
	selectedConnectAccount,
	connectAccounts,
	users
}) =>
{
	const [ exportData, setExportData ] = useState('call');

	const [ selectedDate, setSelectedDate ] = useState({
		startYear        : todayValues.year,
		startMonth       : 1,
		startDay         : 1,
		endYear          : todayValues.year,
		endMonth         : todayValues.month,
		endDay     	     : todayValues.day,
		startMonthDaysAr : [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
			15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31 ],
		endMonthDaysAr : [ ...initialDays ]
	});

	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show    : false,
			title   : '',
			message : ''
		}
	);

	useEffect(() =>
	{
		setExportData('call');
	}, [ page ]);

	const closeConfirmDialog = () =>
	{
		setConfirmDialogState(
			{
				show    : false,
				title   : '',
				message : ''
			}
		);
	};

	const changeDownloadDate = (typeKey, termKey, value) =>
	{
		const newDateValues = {};

		// new value of start/end + Year/Month/Day
		newDateValues[`${typeKey}${termKey}`] = Number(value);

		// no change of days array
		if (termKey==='Day' || (termKey==='Year' && selectedDate.startMonth !== 2))
		{
			setSelectedDate((state) =>
			{
				return {
					...state,
					...newDateValues
				};
			});
		}
		// update array of days and startDay/endDay
		else
		{
			// string of first day of target month
			let dateStr;

			if (termKey === 'Month')
			{
				const yearVal = selectedDate[`${typeKey}Year`];

				dateStr = `${yearVal}-${value}-1`;
			}
			else
			{
				const monthVal = selectedDate[`${typeKey}Month`];

				dateStr = `${value}-${monthVal}-1`;
			}

			// final day of target month
			const endOfMonth =
			dayjs(dateStr, 'YYYY-M-D')
				.endOf('month')
				.get('date');

			// change startDay/endDay if it exceeds end of Month
			let dayVal = selectedDate[`${typeKey}Day`];

			if (dayVal > endOfMonth)
			{
				dayVal = endOfMonth;
			}

			// to update startDay/endDay
			newDateValues[`${typeKey}Day`] = dayVal;

			// to update startMonthDaysAr/endMonthDaysAr(array of days in target Month)
			newDateValues[`${typeKey}MonthDaysAr`] = createDaysAr(endOfMonth);

			setSelectedDate((state) =>
			{
				return {
					...state,
					...newDateValues
				};
			});
		}
	};

	//
	// fetch from fireStore and export csv 
	//
	const downloadFile = ({ data, fileName, fileType }) =>
	{
		const bom = new Uint8Array([ 0xEF, 0xBB, 0xBF ]);
		const blob = new Blob([ bom, data ], { type: fileType });
		const a = document.createElement('a');

		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);

		const clickEvent = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });

		a.dispatchEvent(clickEvent);
		a.remove();
	};

	const exportToCsv = (fileData) =>
	{
		const headers = [ '顧客No.,日付,通話時間(秒数),ID,ポータルID,サービスID,チケットアカウントID,コネクトアカウントID,コネクトキーID' ];

		const body = [];

		fileData.forEach((d) =>
		{
			const {
				number,
				date,
				duration,
				hostLoginId,
				portalId,
				serviceParamKey,
				ticketParamKey,
				connectionAccountId,
				connectionId
			} = d;

			body.push([
				number,
				date,
				duration,
				hostLoginId,
				portalId,
				serviceParamKey ? serviceParamKey : '',
				ticketParamKey ? ticketParamKey : '',
				connectionAccountId ? connectionAccountId : '',
				connectionId ? connectionId : ''
			].join(','));
		});

		downloadFile({
			data     : [ ...headers, ...body ].join('\n'),
			fileName : 'online_calls.csv',
			fileType : 'text/csv'
		});
	};

	const handleFireStoreCallData = (data, allUsers) =>
	{
		const fetchedCalls = [];
		const csvData = [];

		if (page === 'portal')
		{
			data.forEach((doc) =>
			{
				const callData = doc.data();

				if (callData.portalId)
				{
					fetchedCalls.push(callData);
				}
			});
		}
		else if (page === 'serviceAccount')
		{
			data.forEach((doc) =>
			{
				const callData = doc.data();

				if (callData.serviceParamKey)
				{
					fetchedCalls.push(callData);
				}
			});
		}
		else if (page === 'connectAccount')
		{
			data.forEach((doc) =>
			{
				const callData = doc.data();

				if (callData.connectionAccountId)
				{
					fetchedCalls.push(callData);
				}
			});
		}
		else
		{
			data.forEach((doc) =>
			{
				fetchedCalls.push(doc.data());
			});
		}

		if (fetchedCalls.length === 0)
		{
			setConfirmDialogState(
				{
					show    : true,
					title   : '通知',
					message : '該当期間のデータは見つかりませんでした'
				}
			);
		}
		else
		{
			// create user's id and number map to find user number faster
			const usersNumberMap = new Map();

			if (page !== 'connectAccount' && allUsers === true)
			{
				const payingUserPattern = /^R/;

				users.forEach((u) =>
				{
					if (payingUserPattern.test(u.number))
					{
						usersNumberMap.set(u.id, u.number);
					}
				});
			}
			else
			{
				users.forEach((u) =>
				{
					usersNumberMap.set(u.id, u.number);
				});
			}

			const connectAccountsParamKeyMap = new Map();

			connectAccounts.forEach((c) =>
			{
				connectAccountsParamKeyMap.set(c.id, c.paramKey);
			});

			fetchedCalls.forEach((call) =>
			{

				const userNumber = usersNumberMap.get(call.uid);

				const connectAccountParamKey = call.connectionAccountId && connectAccountsParamKeyMap.get(call.connectionAccountId) ? connectAccountsParamKeyMap.get(call.connectionAccountId) : '';

				// uid exists in users collection
				if (userNumber)
				{
					csvData.push(
						{
							number              : userNumber,
							date                : dayjs(call.timestamp).format('YYYY/MM/DD'),
							duration            : call.duration,
							hostLoginId         : call.hostLoginId,
							portalId            : call.portalId ? call.portalId : '',
							serviceParamKey     : call.serviceParamKey ? call.serviceParamKey : '',
							ticketParamKey      : call.ticketParamKey ? call.ticketParamKey : '',
							connectionAccountId : connectAccountParamKey,
							connectionId        : call.connectionId || ''
						}
					);
				}
			});

			exportToCsv(csvData);
		}
	};

	const exportToConnection = (fileData) =>
	{
		const headers = [ 'コネクトアカウントID,コネクトキーID,アカウント名,メールアドレス,電話番号,接続キー,顧客ID,利用開始,利用終了,利用日数,通話時間(秒数),販売価格,卸売価格,手数料,購入経路,従量課金,無効,キャンセル' ];

		const body = [];

		fileData.forEach((d) =>
		{
			const {
				accountParamKey,
				id,
				accountName,
				email,
				phoneNumber,
				connectionKey,
				customerId,
				createdAt,
				expiryTimestamp,
				days,
				duration,
				salesAmount,
				holesaleAmount,
				commission,
				purchasedBy,
				usageBasedBilling,
				disabled,
				orderCanceled
			} = d;

			body.push([
				accountParamKey,
				id,
				accountName,
				email,
				phoneNumber,
				connectionKey,
				customerId,
				createdAt,
				expiryTimestamp,
				days,
				duration,
				salesAmount,
				holesaleAmount,
				commission,
				purchasedBy,
				usageBasedBilling,
				disabled,
				orderCanceled
			].join(','));
		});

		downloadFile({
			data     : [ ...headers, ...body ].join('\n'),
			fileName : 'connections.csv',
			fileType : 'text/csv'
		});
	};

	const handleFireStoreConnectionData = (data, allUsers) =>
	{
		const fetchedConnections = [];
		const csvData = [];

		data.forEach((doc) =>
		{
			fetchedConnections.push({ id: doc.id, ...doc.data() });
		});

		if (allUsers === true)
		{
			fetchedConnections.forEach((c) =>
			{
				csvData.push(
					{
						accountParamKey : c.accountParamKey,
						id              : c.id,
						accountName     : c.accountName,
						email           : c.email,
						phoneNumber     : c.phoneNumber,
						connectionKey   : c.connectionKey,
						customerId      : c.customerId,
						createdAt       : dayjs(c.createdAt).format('YYYY/MM/DD'),
						expiryTimestamp : dayjs(c.expiryTimestamp).format('YYYY/MM/DD'),
						days            : c.createdAt && c.expiryTimestamp ?
							Math.floor((c.expiryTimestamp - c.createdAt)/ (1000*60*60*24)) : '-',
						duration          : c.duration === -1 ? '-': c.duration,
						salesAmount       : c.salesAmount,
						holesaleAmount    : c.holesaleAmount,
						commission        : c.commission,
						purchasedBy       : c.purchasedBy,
						usageBasedBilling : c.usageBasedBilling,
						disabled          : c.disabled,
						orderCanceled     : c.orderCanceled
					}
				);
			});
		}
		else
		{
			fetchedConnections.forEach((c) =>
			{
				if (selectedConnectAccount.includes(c.accountId))
				{
					csvData.push(
						{
							accountParamKey : c.accountParamKey,
							id              : c.id,
							accountName     : c.accountName,
							email           : c.email,
							phoneNumber     : c.phoneNumber,
							connectionKey   : c.connectionKey,
							customerId      : c.customerId,
							createdAt       : dayjs(c.createdAt).format('YYYY/MM/DD'),
							expiryTimestamp : dayjs(c.expiryTimestamp).format('YYYY/MM/DD'),
							days            : c.createdAt && c.expiryTimestamp ?
								Math.floor((c.expiryTimestamp - c.createdAt)/ (1000*60*60*24)) : '-',
							duration          : c.duration === -1 ? '-': c.duration,
							salesAmount       : c.salesAmount,
							holesaleAmount    : c.holesaleAmount,
							commission        : c.commission,
							purchasedBy       : c.purchasedBy,
							usageBasedBilling : c.usageBasedBilling,
							disabled          : c.disabled,
							orderCanceled     : c.orderCanceled
						}
					);
				}
			});
		}

		csvData.sort((a, b) =>
		{
			// asc
			if (a.createdAt < b.createdAt) { return -1; }
			if (a.createdAt > b.createdAt) { return 1; }

			return 0;
		});

		exportToConnection(csvData);
	};

	const exportToCsvSupportCall = (fileData, expData) =>
	{

		const headers = [ '種別,利用顧客No.,利用ホストID,提供顧客No.,提供ホストID,日付,通話時間(秒数)' ];

		const body = [];

		const typeName = expData === 'offerSupport' ? 'サポート提供' : 'サポート利用';

		fileData.forEach((d) =>
		{
			const { numberRequestor,
				hostLoginIdRequestor,
				numberParticipant,
				hostLoginIdParticipant,
				date,
				duration
			} = d;

			body.push([ typeName, numberRequestor, hostLoginIdRequestor, numberParticipant, hostLoginIdParticipant, date, duration ].join(','));
		});

		downloadFile({
			data     : [ ...headers, ...body ].join('\n'),
			fileName : expData === 'offerSupport'
				? 'support_replies.csv'
				: 'support_calls.csv',
			fileType : 'text/csv'
		});
	};

	const handleFireStoreDataSupportCall = (data, allUsers, expData) =>
	{

		const fetchedCalls = [];
		const csvData = [];

		data.forEach((doc) =>
		{
			fetchedCalls.push(doc.data());
		});

		if (fetchedCalls.length === 0)
		{
			setConfirmDialogState(
				{
					show    : true,
					title   : '通知',
					message : '該当期間のデータは見つかりませんでした'
				}
			);
		}
		else
		{

			// create user's id and number map to find user number faster
			const usersNumberMap = new Map();

			if (allUsers === true)
			{
				const payingUserPattern = /^R/;

				users.forEach((u) =>
				{
					if (payingUserPattern.test(u.number))
					{
						usersNumberMap.set(u.id, u.number);
					}
				});
			}
			else
			{
				users.forEach((u) =>
				{
					usersNumberMap.set(u.id, u.number);
				});
			}

			fetchedCalls.forEach((call) =>
			{
				const userNumberParticipant = usersNumberMap.get(call.participantUserId);

				const userNumberRequestor = usersNumberMap.get(call.requestorUserId);

				// uid exists in users collection
				if (userNumberRequestor)
				{
					csvData.push(
						{
							numberRequestor        : userNumberRequestor,
							hostLoginIdRequestor   : call.requestorHostLoginId,
							numberParticipant      : userNumberParticipant,
							hostLoginIdParticipant : call.participantHostLoginId,
							date                   : dayjs(call.timestamp).format('YYYY/MM/DD'),
							duration               : call.duration
						}
					);
				}
			});

			exportToCsvSupportCall(csvData, expData);
		}
	};

	const fetchData = (arg) =>
	{

		if (arg.allUsers !== true && (
			(page === 'portal' && (!selectedPortal || selectedPortal.length === 0))
			|| (page === 'user' && (!selected || selected.length === 0))
			|| (page === 'serviceAccount' && (!selectedServiceAccount || selectedServiceAccount.length === 0))
			|| (page === 'connectAccount' && (!selectedConnectAccount || selectedConnectAccount.length === 0))
		))
		{
			setConfirmDialogState(
				{
					show    : true,
					title   : 'エラー',
					message : '顧客を選択してください'
				}
			);
		}
		else
		{
			const startDateStr = `${selectedDate.startYear}-${selectedDate.startMonth}-${selectedDate.startDay}`;

			const endDateStr = `${selectedDate.endYear}-${selectedDate.endMonth}-${selectedDate.endDay}`;

			const timeStampStartDate = dayjs(startDateStr, 'YYYY-M-D').valueOf();

			let timeStampEndDate = dayjs(endDateStr, 'YYYY-M-D').valueOf();

			if (timeStampEndDate < timeStampStartDate)
			{
				setConfirmDialogState(
					{
						show    : true,
						title   : 'エラー',
						message : '終了日より遅い開始日が指定されています'
					}
				);
			}
			else
			{
				timeStampEndDate += 86400000; // +1day
				// 全ユーザー
				if (arg.allUsers === true)
				{
					// 全ユーザー・通話
					if (exportData === 'call')
					{
						firestore.collection('calls')
							.orderBy('timestamp')
							.where('timestamp', '>=', timeStampStartDate)
							.where('timestamp', '<', timeStampEndDate)
							.get()
							.then((data) =>
							{
								handleFireStoreCallData(data, arg.allUsers);
							})
							.catch((e) =>
							{
								setConfirmDialogState(
									{
										show    : true,
										title   : 'エラー',
										message : 'サーバーエラーが発生しました'
									}
								);
								// eslint-disable-next-line no-console
								console.error('exportCSV error:', e);
							});
					}
					else if (exportData === 'connection')
					{
						firestore.collection('connections')
							.where('createdAt', '>=', timeStampStartDate)
							.where('createdAt', '<', timeStampEndDate)
							.get()
							.then((data) =>
							{
								handleFireStoreConnectionData(data, arg.allUsers);
							})
							.catch(() =>
							{
								setConfirmDialogState(
									{
										show    : true,
										title   : 'エラー',
										message : 'サーバーエラーが発生しました'
									}
								);
							});
					}
					// 全ユーザー・サポート通話
					else
					{
						firestore.collection('supportCalls')
							.orderBy('timestamp')
							.where('timestamp', '>=', timeStampStartDate)
							.where('timestamp', '<', timeStampEndDate)
							.get()
							.then((data) =>
							{
								handleFireStoreDataSupportCall(data, arg.allUsers, exportData);
							})
							.catch((e) =>
							{
								setConfirmDialogState(
									{
										show    : true,
										title   : 'エラー',
										message : 'サーバーエラーが発生しました'
									}
								);
								// eslint-disable-next-line no-console
								console.error('exportCSV error:', e);
							});
					}
				}
				// 選択ユーザー・通話
				else
					if (exportData === 'call')
					{
						let targetAttribute = 'uid';

						let targetIds = [ ...selected ];

						// ポータルIDでしぼりこみ
						if (page === 'portal')
						{
							targetAttribute = 'portalId';
							targetIds = [ ...selectedPortal ];
						}
						else if (page === 'serviceAccount')
						{
							targetAttribute = 'ticketParamKey';
							targetIds = [ ...selectedServiceAccount ];
						}
						else if (page === 'connectAccount')
						{
							targetAttribute = 'connectionAccountId';
							targetIds = [ ...selectedConnectAccount ];
						}

						firestore.collection('calls')
							.orderBy('timestamp')
							.where('timestamp', '>=', timeStampStartDate)
							.where('timestamp', '<', timeStampEndDate)
							.where(targetAttribute, 'in', targetIds)
							.get()
							.then((data) =>
							{
								handleFireStoreCallData(data, arg.allUsers);
							})
							.catch((e) =>
							{
								setConfirmDialogState(
									{
										show    : true,
										title   : 'エラー',
										message : 'サーバーエラーが発生しました'
									}
								);

								// eslint-disable-next-line no-console
								console.error('exportCSV error:', e);
							});
					}
					else if (exportData === 'connection')
					{
						firestore.collection('connections')
							.where('createdAt', '>=', timeStampStartDate)
							.where('createdAt', '<', timeStampEndDate)
							.get()
							.then((data) =>
							{
								handleFireStoreConnectionData(data, arg.allUsers);
							})
							.catch((e) =>
							{
								setConfirmDialogState(
									{
										show    : true,
										title   : 'エラー',
										message : 'サーバーエラーが発生しました'
									}
								);

								// eslint-disable-next-line no-console
								console.error('exportCSV error:', e);
							});
					}
					// 選択ユーザー・サポート通話
					else
					{
						let uidKey = 'requestorUserId';

						if (exportData === 'offerSupport')
						{
							uidKey = 'participantUserId';
						}

						firestore.collection('supportCalls')
							.orderBy('timestamp')
							.where('timestamp', '>=', timeStampStartDate)
							.where('timestamp', '<', timeStampEndDate)
							.where(uidKey, 'in', selected)
							.get()
							.then((data) =>
							{
								handleFireStoreDataSupportCall(data, arg.allUsers, exportData);
							})
							.catch((e) =>
							{
								setConfirmDialogState(
									{
										show    : true,
										title   : 'エラー',
										message : 'サーバーエラーが発生しました'
									}
								);
								// eslint-disable-next-line no-console
								console.error('exportCSV error:', e);
							});
					}
			}
		}
	};

	return (
		<MuiThemeProvider theme={theme}>

			<ConfirmDialog
				show={confirmDialogState.show}
				title={confirmDialogState.title}
				message={confirmDialogState.message}
				accept={() => {}}
				cancel={closeConfirmDialog}
				closeButtonOnly
			/>

			<div>
				<Box className={classes.selectItemInfo}>
					<Typography variant='subtitle1' className={classes.selectItemTitle}>
						CSV出力 期間設定
					</Typography>
				</Box>
				<Box className={classes.selectItemBox}>
					<FormControl variant='outlined' sx={{ m: 1, minWidth: 70 }} size='small'>
						<InputLabel id='start-month-select-label'>年</InputLabel>
						<Select
							labelId='start-month-select'
							id='start-month-select'
							value={selectedDate.startYear}
							onChange={(e) => changeDownloadDate('start', 'Year', e.target.value)}
							className={classes.selectItem}
							label='年'
						>
							{
								yearsToToday.map((y) => (
									<MenuItem value={y} key={y}>{y}</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<FormControl
						variant='outlined'
						sx={{ m: 1, minWidth: 50 }}
						size='small'
						className={classes.selectMonthDay}
					>
						<InputLabel id='start-month-select-label'>月</InputLabel>
						<Select
							labelId='start-month-select'
							id='start-month-select'
							value={selectedDate.startMonth}
							onChange={(e) => changeDownloadDate('start', 'Month', e.target.value)}
							className={classes.selectItem}
							label='月'
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
							<MenuItem value={5}>5</MenuItem>
							<MenuItem value={6}>6</MenuItem>
							<MenuItem value={7}>7</MenuItem>
							<MenuItem value={8}>8</MenuItem>
							<MenuItem value={9}>9</MenuItem>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={11}>11</MenuItem>
							<MenuItem value={12}>12</MenuItem>
						</Select>
					</FormControl>

					<FormControl
						variant='outlined'
						sx={{ m: 1, minWidth: 50 }}
						size='small'
						className={classes.selectMonthDay}
					>
						<InputLabel id='start-month-select-label'>日</InputLabel>
						<Select
							labelId='start-day-select'
							id='start-day-select'
							value={selectedDate.startDay}
							onChange={(e) => changeDownloadDate('start', 'Day', e.target.value)}
							className={classes.selectItem}
							label='日'
						>
							{
								selectedDate.startMonthDaysAr.map((d) => (
									<MenuItem value={d} key={d}>{d}</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<Typography variant='h6' className={classes.separator}>
						~
					</Typography>

					<FormControl variant='outlined' sx={{ m: 1, minWidth: 70 }} size='small'>
						<InputLabel id='start-month-select-label'>年</InputLabel>
						<Select
							labelId='start-month-select'
							id='start-month-select'
							value={selectedDate.endYear}
							onChange={(e) => changeDownloadDate('end', 'Year', e.target.value)}
							className={classes.selectItem}
							label='年'
						>
							{
								yearsToToday.map((y) => (
									<MenuItem value={y} key={y}>{y}</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<FormControl
						variant='outlined'
						sx={{ m: 1, minWidth: 50 }}
						size='small'
						className={classes.selectMonthDay}
					>
						<InputLabel id='start-month-select-label'>月</InputLabel>
						<Select
							labelId='start-month-select'
							id='start-month-select'
							value={selectedDate.endMonth}
							onChange={(e) => changeDownloadDate('end', 'Month', e.target.value)}
							className={classes.selectItem}
							label='月'
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
							<MenuItem value={5}>5</MenuItem>
							<MenuItem value={6}>6</MenuItem>
							<MenuItem value={7}>7</MenuItem>
							<MenuItem value={8}>8</MenuItem>
							<MenuItem value={9}>9</MenuItem>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={11}>11</MenuItem>
							<MenuItem value={12}>12</MenuItem>
						</Select>
					</FormControl>

					<FormControl
						variant='outlined'
						sx={{ m: 1, minWidth: 50 }}
						size='small'
						className={classes.selectMonthDay}
					>
						<InputLabel id='start-month-select-label'>日</InputLabel>
						<Select
							labelId='start-day-select'
							id='start-day-select'
							value={selectedDate.endDay}
							onChange={(e) => changeDownloadDate('end', 'Day', e.target.value)}
							className={classes.selectItem}
							label='日'
						>
							{
								selectedDate.endMonthDaysAr.map((d) => (
									<MenuItem value={d} key={d}>{d}</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<Box className={classes.dataSelect}>
						<FormControl
							variant='outlined'
							sx={{ m: 1, minWidth: 50 }}
							size='small'
						>
							<InputLabel id='select-type'>種別</InputLabel>
							<Select
								labelId='select-type'
								id='select-type'
								className={`${classes.selectItem} ${classes.typeSelect}`}
								value={exportData}
								onChange={(e) => setExportData(e.target.value)}
								label='種別'
								disabled={page==='portal' || page==='serviceAccount'}
							>
								<MenuItem value='call'>通話</MenuItem>
								{ page === 'user' &&
									<MenuItem value='offerSupport'>サポート提供</MenuItem>
								}

								{ page === 'user' &&
									<MenuItem value='useSupport'>サポート利用</MenuItem>
								}
								{ page === 'connectAccount' &&
									<MenuItem value='connection'>接続キー</MenuItem>
								}
							</Select>
						</FormControl>
					</Box>

					<Button
						variant='contained'
						className={`${classes.exportBtn} ${classes.firstExportBtn} `}
						onClick={() => fetchData({ allUsers: false })}
						disabled={(page==='portal' && selectedPortal?.length === 0)
							|| (page=== 'user' && selected?.length === 0)
							|| (page==='serviceAccount' && selectedServiceAccount?.length === 0)
							|| (page==='connectAccount' && selectedConnectAccount?.length === 0)
						}
					>
						選択出力
					</Button>

					<Button
						variant='contained'
						className={`${classes.exportBtn} ${classes.secondExportBtn} `}
						onClick={() => fetchData({ allUsers: true })}
					>
						全顧客出力
					</Button>
				</Box>
			</div>
		</MuiThemeProvider>

	);
};

AdminDateSelectDownload.propTypes =
{
	classes                : PropTypes.object.isRequired,
	page                   : PropTypes.string.isRequired,
	selected               : PropTypes.array.isRequired,
	selectedPortal         : PropTypes.array.isRequired,
	selectedServiceAccount : PropTypes.array.isRequired,
	selectedConnectAccount : PropTypes.array.isRequired,
	connectAccounts        : PropTypes.array.isRequired,
	users                  : PropTypes.array.isRequired
};

export default withStyles(styles)(AdminDateSelectDownload);