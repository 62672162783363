import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

// material Ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import MonthlySalesAmount from '../../Common/MonthlySalesAmount';

const styles = () => ({
	root : {
		backgroundColor : '#FFF',
		width           : '100%',
		borderRadius    : '20px',
		padding         : '20px 20px 21px 20px'
	},
	monthlySalesLabel : {
		paddingTop : '10px',
		display    : 'flex',
		width      : '100%',
		borderTop  : '2px solid #CC2E80'
	},
	valueSection : {
		paddingTop : '10px',
		display    : 'flex',
		width      : '100%'
	},
	valueSectionAll : {
		borderTop : '4px solid #343434'
	},
	valueSectionActive : {
		borderTop : '4px solid #FFDC00'
	},
	valueSectionBeforeActive : {
		borderTop : '4px solid #00B79C'
	},
	valueSectionContent : {
		marginLeft     : '20px',
		width          : '100%',
		maxWidth       : '440px',
		display        : 'flex',
		justifyContent : 'space-between'
	},
	valueTitle : {
		display    : 'flex',
		alignItems : 'flex-end'
	},
	valueTitleText : {
		fontSize   : '1.2rem',
		lineHeight : '20px',
		height     : '20px'
	},
	valueText : {
		display        : 'flex',
		justifyContent : 'space-between',
		alignItems     : 'flex-end',
		borderBottom   : '1px solid #343434',
		paddingBottom  : '1px'
	},
	valueLabel : {
		fontSize : '0.9rem',
		margin   : '0 30px 2px 10px'
	},
	valueCount : {
		fontSize : '1.2rem',
		color    : '#292929',
		margin   : '0 10px 2px 10px'
	},
	title : {
		color      : '#292929',
		fontSize   : '1.1rem',
		fontWeight : 'bold'
	}

});

const ConnectUserCount = ({
	classes,
	page,
	isZeus,
	targetDate,
	purchasedCount,
	purchasedAmount,
	holesalePurchasedAmount,
	count
}) =>
{
	return (
		<Box className={classes.root}>
			{ !isZeus ?
				<>
					<Box className={classes.monthlySalesLabel}>
						<Typography className={classes.title}>
							購入情報
						</Typography>
					</Box>
					< MonthlySalesAmount
						originPage={'manage'}
						data={'purchase'}
						year={targetDate.year}
						month={targetDate.month}
						paymentYear={targetDate.nextMonthYear}
						paymentMonth={targetDate.nextMonth}
						ticketsListLength={purchasedCount}
						salesAmount={purchasedAmount}
						holesaleAmount={holesalePurchasedAmount}
						commissionAmount={null}
					/>
				</>
				:
				<Box
					className={
						page === 'list-active' ? `${classes.valueSection} ${classes.valueSectionActive}`
							: page === 'list-before-active' ? `${classes.valueSection} ${classes.valueSectionBeforeActive}`
								: `${classes.valueSection} ${classes.valueSectionAll}`
					}
				>
					<Box className={classes.valueSectionContent}>
						<Box className={classes.valueTitle}>
							<Typography className={classes.valueTitleText}>
								{ page === 'list-active' ? '稼働ユーザー情報'
									: page === 'list-before-active' ? 'スタンバイユーザー情報'
										: '発行ユーザー情報' }
							</Typography>
						</Box>

						<Box className={classes.valueText}>
							<Typography className={classes.valueLabel}>
								{ page === 'list-active' ? '現在の稼働ユーザー数'
									: page === 'list-before-active ' ? '現在のスタンバイユーザー数'
										: '現在の発行ユーザー数' }
							</Typography>

							<Typography className={classes.valueCount}>
								{count}件
							</Typography>
						</Box>
					</Box>
				</Box>

			}
		</Box>
	);
};

ConnectUserCount.propTypes =
{
	classes                 : PropTypes.object.isRequired,
	page                    : PropTypes.string.isRequired,
	isZeus                  : PropTypes.bool.isRequired,
	targetDate              : PropTypes.object,
	purchasedCount          : PropTypes.number,
	purchasedAmount         : PropTypes.number,
	holesalePurchasedAmount : PropTypes.number,
	count                   : PropTypes.number
};

export default withStyles(styles)(ConnectUserCount);
