import {
	CALL_STATUS_CATCH,
	CALL_STATUS_FINISHED,
	CALL_STATUS_NO_ONE_AVAILABLE,
	CALL_STATUS_NO_REPLY,
	CALL_STATUS_STOP,
	ROOM_ONLINE,
	ROOM_CALLING,
	WAITING_GUESTS_STATUS_CONNECTED,
	// WAITING_GUESTS_STATUS_DISCONNECT,
	YEAR_MONTH
} from '../../../../../../../const';
import { roundToDecimalPlaces } from '../../utils/roundDecimal';

import { calcSecondsFromTimestampForDay, calcSecondsFromTimestampForMonth } from '../../utils/SecondsFromTime';

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */

const filterCalls = (funcGetDate, dateTime, calls) =>
{
	const condDate = funcGetDate(dateTime);

	const filteredCalls = calls.filter((item) =>
	{
		const date = funcGetDate(item.timestamp);

		return condDate === date;
	});

	return filteredCalls;
};

const filterRoomSnapshots = (funcGetDate, dateTime, roomsSnapshots) =>
{
	const condDate = funcGetDate(dateTime);

	const filteredSnapshots = roomsSnapshots.filter((item) =>
	{

		const date = funcGetDate(item.timestamp);

		return condDate === date;
	});

	return filteredSnapshots;
};

const filterQueueResults = (funcGetDate, dateTime, queueResults) =>
{
	const condDate = funcGetDate(dateTime);

	const filteredQueueResults = queueResults.filter((item) =>
	{
		const date = funcGetDate(item.start);

		return condDate === date;
	});

	return filteredQueueResults;
};

export const countNumberOfIncomingCalls = (calls) =>
{
	if (!calls)
	{
		return 0;
	}

	return calls.length;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countNumberOfCallsReceived = (calls) =>
{
	if (!calls)
	{
		return 0;
	}

	const numberOfCallsReceived = calls.reduce((accumulator, item) =>
	{

		if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
		{
			accumulator++;
		}

		return accumulator;
	}, 0);

	return numberOfCallsReceived;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number} - seconds
 */
export const countTimeWaiting = (roomsSnapshots) =>
{
	if (!roomsSnapshots)
	{
		return 0;
	}

	const totalTimeWaiting = roomsSnapshots.reduce((accumulator, item) =>
	{
		for (const rm of item.rooms)
		{
			if ((rm.status === ROOM_ONLINE || rm.status === ROOM_CALLING))
			{
				// 部屋全て合算 基本5分(300秒)ごと
				accumulator += item.intervalMinute ? item.intervalMinute * 60 : 300;
			}
		}

		return accumulator;
	}, 0);

	return totalTimeWaiting;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number} - seconds
 */
export const getTotalTalkTime = (calls) =>
{
	if (!calls)
	{
		return 0;
	}

	const totalTalkTime = calls.reduce((accumulator, item) =>
	{

		accumulator = accumulator + item.duration;

		return accumulator;
	}, 0);

	return totalTalkTime;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countNumberOfMissedCalls = (calls) =>
{
	if (!calls)
	{
		return 0;
	}

	const numberOfMissedCalls = calls.reduce((accumulator, item) =>
	{
		if (
			item.status === CALL_STATUS_NO_REPLY
					|| item.status === CALL_STATUS_NO_ONE_AVAILABLE
		)
		{
			accumulator++;
		}

		return accumulator;
	}, 0);

	return numberOfMissedCalls;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countNumberOfBreaks = (calls) =>
{
	if (!calls)
	{
		return 0;
	}

	const numberOfBreaks = calls.reduce((accumulator, item) =>
	{

		if (
			item.status === CALL_STATUS_STOP
		)
		{
			accumulator++;
		}

		return accumulator;
	}, 0);

	return numberOfBreaks;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number} - milliseconds
 */

const funcCountNumberOfCallsWaiting = (queueResults) =>
{
	if (!queueResults)
	{
		return 0;
	}

	const callsWaitingNumber =
			queueResults.reduce((accumulator, item) =>
			{

				if (item.status === WAITING_GUESTS_STATUS_CONNECTED
						|| item.end - item.start >= 10000) accumulator++;

				return accumulator;
			}, 0);

	return callsWaitingNumber;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number} - milliseconds
 */
export const getCallWaitingAverageWaitingTime = (queueResults) =>
{
	if (!queueResults)
	{
		return [];
	}

	let numberWaitingGuests = 0;
	const callWaitingTotalWaitingTime = queueResults.reduce((accumulator, item) =>
	{

		numberWaitingGuests++;
		const timeWait = item.end - item.start;

		accumulator += timeWait;

		return accumulator;
	}, 0);

	return callWaitingTotalWaitingTime / numberWaitingGuests;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countCallWaitingNumberOfSuccessfulConnections
	= (queueResults) =>
	{

		if (!queueResults)
		{
			return 0;
		}

		const callWaitingNumberOfSuccessfulConnections =
			queueResults.reduce((accumulator, item) =>
			{

				if (item.status === WAITING_GUESTS_STATUS_CONNECTED) accumulator++;

				return accumulator;
			}, 0);

		return callWaitingNumberOfSuccessfulConnections;
	};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
// export const countCallWaitingNumberOfExits = (funcGetDate, dateTime, queueResults) =>
// {
// 	if (!queueResults)
// 	{
// 		return [];
// 	}

// 	const callWaitingNumberOfExits = queueResults.reduce((accumulator, item) =>
// 	{
// 		const date = funcGetDate(item.start);

// 		if (funcGetDate(dateTime) === date)
// 		{
// 			if (item.status === WAITING_GUESTS_STATUS_DISCONNECT
// 				&& item.end - item.start >= 10000)
// 			{
// 				accumulator++;
// 			}
// 		}

// 		return accumulator;
// 	}, 0);

// 	return callWaitingNumberOfExits;
// };

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @param {number} checkDate
 * @returns {object} - dataOverallYearMonth or dataOverallMonthDay
 */
export const getDataOverall = (
	subdomains,
	funcGetDate,
	dateTime,
	checkDate,
	calls,
	roomsSnapshots,
	queueResults
) =>
{
	const dateMatchedCalls = filterCalls(funcGetDate, dateTime, calls);

	const dateMatchedRoomSnapshots
		= filterRoomSnapshots(funcGetDate, dateTime, roomsSnapshots);

	const dateMatchedQueueResults = filterQueueResults(funcGetDate, dateTime, queueResults);

	const numberOfIncomingCalls = countNumberOfIncomingCalls(dateMatchedCalls);

	const numberOfCallsReceived = countNumberOfCallsReceived(dateMatchedCalls);

	const callReceivedRate = numberOfIncomingCalls ?
		(numberOfCallsReceived / numberOfIncomingCalls) * 100
		: 0;

	const timeWaiting = countTimeWaiting(dateMatchedRoomSnapshots); // seconds

	const totalTalkTime = getTotalTalkTime(dateMatchedCalls); // seconds

	const upTime = totalTalkTime + timeWaiting; // seconds

	let numberOfActiveSeatsAverage = 0;

	// 月ごと
	if (checkDate === YEAR_MONTH)
	{
		const workingSeconds = calcSecondsFromTimestampForMonth(funcGetDate(dateTime))
								* subdomains.length;

		numberOfActiveSeatsAverage = timeWaiting / workingSeconds;
	}
	// 日ごと
	else
	{
		const workingSeconds = calcSecondsFromTimestampForDay(funcGetDate(dateTime));

		numberOfActiveSeatsAverage = upTime / workingSeconds;
	}

	const averageTalkTime = totalTalkTime / numberOfCallsReceived; // seconds

	const numberOfMissedCalls = countNumberOfMissedCalls(dateMatchedCalls);

	const numberOfBreaks = countNumberOfBreaks(dateMatchedCalls);

	const numberOfCallsWaiting
		= funcCountNumberOfCallsWaiting(dateMatchedQueueResults);

	const callWaitingAverageWaitingTime =
		getCallWaitingAverageWaitingTime(dateMatchedQueueResults); // milliseconds

	const callWaitingNumberOfSuccessfulConnections =
		countCallWaitingNumberOfSuccessfulConnections(
			dateMatchedQueueResults
		);

	const callWaitingNumberOfExits =
		numberOfCallsWaiting - callWaitingNumberOfSuccessfulConnections;

	const callWaitingRate = numberOfCallsWaiting / numberOfIncomingCalls * 100;

	return {
		numberOfIncomingCalls,
		numberOfCallsReceived,
		callReceivedRate              : roundToDecimalPlaces(callReceivedRate, 2),
		numberOfActiveSeats           : roundToDecimalPlaces(numberOfActiveSeatsAverage, 3),
		upTime                        : roundToDecimalPlaces(upTime / 60, 2),
		totalTalkTime                 : roundToDecimalPlaces(totalTalkTime / 60, 2),
		averageTalkTime               : roundToDecimalPlaces(averageTalkTime / 60, 2),
		numberOfMissedCalls,
		numberOfBreaks,
		numberOfCallsWaiting,
		callWaitingRate               : roundToDecimalPlaces(callWaitingRate, 2),
		callWaitingAverageWaitingTime : roundToDecimalPlaces(
			callWaitingAverageWaitingTime / (60 * 1000),
			2
		),
		callWaitingNumberOfSuccessfulConnections,
		callWaitingNumberOfExits
	};
};

/**
 * @param {Date} dataOverallYearMonth
 * @param {Date} dataOverallMonthDay
 * @returns {Array}
 */
export const createRows = (dataOverallYearMonth, dataOverallMonthDay) =>
{
	const rows = [
		[
			{
				name    : '入電数',
				metric1 : `${dataOverallYearMonth.numberOfIncomingCalls} 件`,
				metric2 : `${dataOverallMonthDay.numberOfIncomingCalls} 件`
			},
			{
				name    : '受電数',
				metric1 : `${dataOverallYearMonth.numberOfCallsReceived} 件`,
				metric2 : `${dataOverallMonthDay.numberOfCallsReceived} 件`
			},
			{
				name    : '受電率',
				metric1 : `${dataOverallYearMonth.callReceivedRate}%`,
				metric2 : `${dataOverallMonthDay.callReceivedRate}%`
			}
		],
		[
			{
				name    : '稼働席数',
				metric1 : `${dataOverallYearMonth.numberOfActiveSeats} 席`,
				metric2 : `${dataOverallMonthDay.numberOfActiveSeats} 席`
			},
			{
				name    : '稼働時間',
				metric1 : `${dataOverallYearMonth.upTime} 分`,
				metric2 : `${dataOverallMonthDay.upTime} 分`
			},
			{
				name    : '合計通話時間',
				metric1 : `${dataOverallYearMonth.totalTalkTime} 分`,
				metric2 : `${dataOverallMonthDay.totalTalkTime} 分`
			},
			{
				name    : '平均通話時間',
				metric1 : `${dataOverallYearMonth.averageTalkTime} 分`,
				metric2 : `${dataOverallMonthDay.averageTalkTime} 分`
			}
		],
		[
			{
				name    : '不在数',
				metric1 : `${dataOverallYearMonth.numberOfMissedCalls} 件`,
				metric2 : `${dataOverallMonthDay.numberOfMissedCalls} 件`
			},
			{
				name    : '切断数',
				metric1 : `${dataOverallYearMonth.numberOfBreaks} 件`,
				metric2 : `${dataOverallMonthDay.numberOfBreaks} 件`
			}
		],
		[
			{
				name    : '待ち呼数',
				metric1 : `${dataOverallYearMonth.numberOfCallsWaiting} 件`,
				metric2 : `${dataOverallMonthDay.numberOfCallsWaiting} 件`
			},
			{
				name    : '待ち呼率',
				metric1 : `${dataOverallYearMonth.callWaitingRate}%`,
				metric2 : `${dataOverallMonthDay.callWaitingRate}%`
			},
			{
				name    : '待ち呼　平均待機時間',
				metric1 : `${dataOverallYearMonth.callWaitingAverageWaitingTime} 分`,
				metric2 : `${dataOverallMonthDay.callWaitingAverageWaitingTime} 分`
			},
			{
				name    : '待ち呼　接続成功数',
				metric1 : `${dataOverallYearMonth.callWaitingNumberOfSuccessfulConnections} 件`,
				metric2 : `${dataOverallMonthDay.callWaitingNumberOfSuccessfulConnections} 件`
			},
			{
				name    : '待ち呼　離脱数',
				metric1 : `${dataOverallYearMonth.callWaitingNumberOfExits} 件`,
				metric2 : `${dataOverallMonthDay.callWaitingNumberOfExits} 件`
			}
		]
	];

	return rows;
};
