import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
	textField : {
		'& input' : {
			fontSize : '0.9rem !important'
		}
	},
	label : {
		fontSize : '0.7rem',
		color    : 'rgba(0, 0, 0, 0.54)'
	}
}));

export default function DatePickerYearMonth({
	dateYearMonth,
	setDateYearMonth,
	currentMonth
})
{
	const classes = useStyles();

	const handleMonthChange = (e) =>
	{

		if (e.target?.value)
		{
			setDateYearMonth(e.target.value);
		}
	};

	return (
		<Box>
			<Typography className={classes.label}>
				年&nbsp;/&nbsp;月
			</Typography>
			<TextField
				className={classes.textField}
				type='month'
				value={dateYearMonth}
				max={currentMonth}
				onChange={(e) => handleMonthChange(e)}
				classes={{
					root : classes.textField
				}}
			/>
		</Box>
	);
}

DatePickerYearMonth.propTypes = {
	dateYearMonth    : PropTypes.string.isRequired,
	setDateYearMonth : PropTypes.func.isRequired,
	currentMonth     : PropTypes.string.isRequired
};
