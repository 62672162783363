import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DatePickerYearMonth from '../../utils/DatePickerYearMonth';
import DatePickerMonthDay from '../../utils/DatePickerMonthDay';

import constants from '../../constants';
import { MONTH_DAY, YEAR_MONTH } from '../../../../../../../const';
import { getYearMonthDay, getYearMonth } from '../../utils/formatDate';
import { exportToCSV } from '../../utils/exportCSV';
import { createRows, getDataOverall } from './overallCalculator';

// dayjs
import dayjs from 'dayjs';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor                      : constants.backgroundColorHead,
		color                                : theme.palette.common.white,
		width                                : '33.33%',
		'&:nth-of-type(2), &:nth-of-type(3)' : {
			textAlign : 'center'
		}
	},
	body : {
		width           : '33.33%',
		fontSize        : 14,
		height          : constants.heightTableRow,
		border          : '1px solid #E0E0E0',
		padding         : '0px 16px',
		backgroundColor : '#FFF'
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)' : {
			backgroundColor : theme.palette.action.hover
		}
	}
}))(TableRow);

const useStyles = makeStyles({
	...constants.tableRowStyles,
	table : {
		...constants.tableRowStyles.table,
		minWidth : 560
	},
	cellHead : {
		borderRight : '1px solid #c3c3c3',
		padding     : '8px'
	},
	title : {
		display     : 'inline-block',
		marginTop   : 45,
		color       : '#c3c3c3',
		paddingLeft : 12
	},
	fieldActiveSeats : {
		display        : 'flex',
		justifyContent : 'space-between',
		alignItems     : 'center'
	},
	firstSpan : {
		color     : '#c3c3c3',
		fontSize  : 8,
		transform : 'translateY(1px)'
	},
	hostOverallContainerHeader : {
		display        : 'flex',
		justifyContent : 'space-between',
		width          : '100%',
		borderBottom   : '2px solid #cacaca',
		padding        : '15px 5px 5px 12px'
	},
	hostOverallPerformanceText : {
		fontSize   : '13px',
		fontWeight : 600,
		color      : '#525252'
	},
	hostOverallButtonCsv : {
		padding         : '2px 25px',
		backgroundColor : '#e65600',
		border          : 'none',
		borderRadius    : '5px',
		color           : '#FFF',
		cursor          : 'pointer',
		fontSize        : '12px'
	},
	hostCustomDatePicker : {
		'& .MuiTableCell-root' : {
			padding : '0px 16px !important'
		},
		'& .MuiInputBase-root' : {
			padding   : '4px !important',
			marginTop : '0px !important'
		},
		'& .MuiFormControl-root' : {
			marginTop    : '12px !important',
			marginBottom : '7px !important'
		}
	},
	hostTableRow : {
		'& th:first-child' : {
			borderRadius : '8px 0 0 0'
		},
		'& th:last-child' : {
			borderRadius : '0 8px 0 0'
		}
	},
	hostGlobalText : {
		'& th, & td' : {
			color : '#939292'
		}
	}
});

const currentMonth = dayjs()
	.tz('Asia/Tokyo')
	.format('YYYY-MM');

const currentDay = dayjs()
	.tz('Asia/Tokyo')
	.format('YYYY-MM-DD');

export default function Overall({
	subdomains,
	calls,
	roomsSnapshots,
	queueResults
})
{
	const classes = useStyles();

	const [ dataOverallYearMonth, setDataOverallYearMonth ] = useState({
		numberOfIncomingCalls                    : 0,
		numberOfCallsReceived                    : 0,
		callReceivedRate                         : 0,
		numberOfActiveSeats                      : 0,
		upTime                                   : 0,
		totalTalkTime                            : 0,
		averageTalkTime                          : 0,
		numberOfMissedCalls                      : 0,
		numberOfBreaks                           : 0,
		numberOfCallsWaiting                     : 0,
		callWaitingRate                          : 0,
		callWaitingAverageWaitingTime            : 0,
		callWaitingNumberOfSuccessfulConnections : 0,
		callWaitingNumberOfExits                 : 0
	});

	const [ dataOverallMonthDay, setDataOverallMonthDay ] = useState({
		numberOfIncomingCalls                    : 0,
		numberOfCallsReceived                    : 0,
		callReceivedRate                         : 0,
		numberOfActiveSeats                      : 0,
		upTime                                   : 0,
		totalTalkTime                            : 0,
		averageTalkTime                          : 0,
		numberOfMissedCalls                      : 0,
		numberOfBreaks                           : 0,
		numberOfCallsWaiting                     : 0,
		callWaitingRate                          : 0,
		callWaitingAverageWaitingTime            : 0,
		callWaitingNumberOfSuccessfulConnections : 0,
		callWaitingNumberOfExits                 : 0
	});

	const [ dateYearMonth, setDateYearMonth ] = useState(currentMonth);
	const [ dateMonthDay, setDateMonthDay ] = useState(currentDay);
	const rows = createRows(dataOverallYearMonth, dataOverallMonthDay);

	useEffect(() =>
	{
		const data = getDataOverall(
			subdomains,
			getYearMonth,
			dateYearMonth,
			YEAR_MONTH,
			calls,
			roomsSnapshots,
			queueResults);

		setDataOverallYearMonth((prev) => ({
			...prev,
			...data
		}));
	}, [ subdomains, dateYearMonth, calls, roomsSnapshots, queueResults ]);

	useEffect(() =>
	{
		const data = getDataOverall(
			subdomains,
			getYearMonthDay,
			dateMonthDay,
			MONTH_DAY,
			calls,
			roomsSnapshots,
			queueResults
		);

		setDataOverallMonthDay((prev) => ({
			...prev,
			...data
		}));
	}, [ subdomains, dateMonthDay, calls, roomsSnapshots, queueResults ]);

	const addText = (text, data) =>
	{
		return (
			<p className={classes.fieldActiveSeats}>
				<span className={classes.firstSpan}>{text}</span>
				<span>{data}</span>
			</p>
		);
	};

	return (
		<TableContainer component={Paper} className={classes.container}>
			<div className={classes.hostOverallContainerHeader}>
				<div className={classes.hostOverallPerformanceText}>全体パフォーマンス</div>
				<button
					className={classes.hostOverallButtonCsv}
					onClick={() => exportToCSV(rows, 'data-host-overall.csv', dateYearMonth, dateMonthDay)}
				>
					CSV 出力
				</button>
			</div>
			<span className={classes.title}>月毎・日毎</span>
			<Table className={classes.table} aria-label='customized table'>
				<TableHead className={classes.hostCustomDatePicker}>
					<TableRow className={classes.hostTableRow}>
						<StyledTableCell className={classes.cellHead}>項目</StyledTableCell>
						<StyledTableCell className={classes.cellHead} align='right'>
							<DatePickerYearMonth
								dateYearMonth={dateYearMonth}
								setDateYearMonth={setDateYearMonth}
								currentMonth={currentMonth}
							/>
						</StyledTableCell>
						<StyledTableCell align='right'>
							<DatePickerMonthDay
								dateMonthDay={dateMonthDay}
								setDateMonthDay={setDateMonthDay}
								currentDay={currentDay}
							/>
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody className={classes.hostGlobalText}>
					{rows.map((group) =>
						group.map((data, dataIndex) =>
						{
							const isLastRow = dataIndex === group.length - 1;
							const isFieldActiveSeats = data.name === '稼働席数';

							return (
								<StyledTableRow key={data.name} className={isLastRow ? classes.lastRow : ''}>
									<StyledTableCell
										component='th'
										scope='row'
										className={isLastRow ? classes.categoryCell : ''}
									>
										{data.name}
									</StyledTableCell>

									<StyledTableCell align='right'>
										{isFieldActiveSeats ? addText('[月平均]', data.metric1) : data.metric1}
									</StyledTableCell>
									<StyledTableCell align='right'>
										{isFieldActiveSeats ? addText('[1日平均]', data.metric2) : data.metric2}
									</StyledTableCell>
								</StyledTableRow>
							);
						})
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
