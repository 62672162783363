import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import Button from '@material-ui/core/Button';

// components
import ConnectUserCount from './ConnectUserCount';
import ExpiryDialog from '../../Common/ExpiryDialog';

// material Ui
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core//Checkbox';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';

// util 
import { showTaxIncludedAmount } from '../../../../utils';

// const 
import { CONSUMPTION_TAX } from '../../../../const';

// dayjs
import dayjs from 'dayjs';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/objectSupport'));
dayjs.tz.setDefault('Asia/Tokyo');

const drawerWidth = 250;

const styles = (theme) =>
	({
		homeWrapper : {
			width           : '100%',
			height          : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			overflowX       : 'auto',
			backgroundColor : '#FFF',
			borderRadius    : '5px'
		},
		monthlyWrapper : {
			width           : '100%',
			height          : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			backgroundColor : 'transparent',
			borderRadius    : '5px'
		},
		root :
		{
			width                        : '100%',
			height                       : '100%',
			display                      : 'flex',
			flexDirection                : 'column',
			maxWidth                     : '1670px',
			padding                      : '0 20px',
			position                     : 'relative',
			color                        : 'var(--text-color)',
			fontSize                     : '0.9rem',
			overflowX                    : 'auto',
			[theme.breakpoints.up('sm')] : {
				width : `calc( 100vw - ${drawerWidth+40}px )`
			}
		},
		homeInfoSection : {
			marginTop : '10px'
		},
		infoBox : {
			width     : '100%',
			maxWidth  : '1290px',
			border    : '1px solid #292929',
			position  : 'relative',
			height    : '125px',
			marginTop : '40px',
			padding   : '8px'
		},
		infoTitle : {
			backgroundColor : '#FFF',
			position        : 'absolute',
			top             : '-18px',
			left            : '40px',
			height          : '20px',
			width           : '120px',
			textAlign       : 'center'
		},
		infoContentBox : {
			width     : '100%',
			height    : '100%',
			overflow  : 'auto',
			listStyle : 'inside'
		},
		infoContent : {
			width     : '100%',
			marginTop : '10px'
		},
		infoContentItem : {
			display    : 'list-item',
			color      : '#292929',
			fontSize   : '0.8rem',
			margin     : '3px 5px',
			fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
		},
		infoContentTitle : {
			textDecoration : 'underline',
			cursor         : 'pointer'
		},
		notificationDate : {
			marginRight : '14px'
		},
		homeContentSection : {
			minHeight : '400px',
			marginTop : '20px'
		},
		homeContentBox : {
			width        : '100%',
			maxWidth     : '1290px',
			minHeight    : '180px',
			marginTop    : '50px',
			padding      : '15px',
			borderRadius : '10px',
			boxShadow    : '1px 1px 3px #bbb inset'
		},
		homeContentBoxSold : {
			backgroundColor : '#F1FFFF'
		},
		homeContentBoxPurchased : {
			backgroundColor : '#FFE7F0'
		},
		detailButton : {
			marginTop : '15px',
			color     : '#FFF',
			padding   : '2px 30px'
		},
		detailButtonSales : {
			backgroundColor : '#036EB6',
			'&:hover' :
			{
				backgroundColor : '#036EB6'
			}
		},
		detailButtonPurchase : {
			backgroundColor : '#CD2C82',
			'&:hover' :
			{
				backgroundColor : '#CD2C82'
			}
		},
		listHeaderSection : {
			marginTop : '10px'
		},
		listActionSection : {
			marginTop : '10px'
		},
		listTableSection : {
			marginTop : '10px'
		},
		tableSection : {
			overflowY       : 'auto',
			flexGrow        : '1',
			padding         : '10px',
			backgroundColor : '#FFF',
			borderRadius    : '10px'
		},
		tableContainer : {
			maxHeight : '100%'
		},
		table : {
			overflowX : 'hidden'
		},
		searchStatusBox : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		searchStatus : {
			minWidth       : '5rem',
			maxWidth       : '8rem',
			padding        : '2px 0',
			borderRadius   : '0.5rem',
			fontSize       : '0.8rem',
			color          : '#292929',
			boxShadow      : '2px 2px 4px inset #aaa',
			justifyContent : 'center',
			alignItems     : 'center',
			display        : 'flex',
			lineHeight     : 1.5,
			whiteSpace     : 'nowrap'
		},
		searchStatusActive : {
			backgroundColor : '#FFDC00'
		},
		searchStatusBeforeActive : {
			backgroundColor : '#00B79C'
		},
		searchStatusEnded : {
			backgroundColor : '#989797'
		},
		tableBtn : {
			padding : '3px 8px'
		},
		expiryBtn : {
			backgroundColor : '#E2E2E2 !important',
			color           : '#292929'
		},
		navigationSection :
		{
			marginTop      : '10px',
			display        : 'flex',
			justifyContent : 'flex-start',
			flexWrap       : 'wrap',
			fontSize       : '1rem',
			marginBottom   : '10px'
		},
		searchArea : {
			display : 'flex',
			width   : 'calc(100% - 90px)'
		},
		searchGroup : {
			color          : '#878686',
			fontSize       : '0.9rem',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'flex-start',
			margin         : '0 8px 12px 8px',
			[theme.breakpoints.down('xs')] :
			{
				padding : '0.5rem 0'
			}
		},
		searchGroupDate : {
			width : '350px'
		},
		searchGroupInput : {
			flexGrow : '1'
		},
		searchTitle : {
			width     : '8rem',
			textAlign : 'left'
		},
		searchGroupSearch : {
			width      : '80px',
			color      : '#878686',
			fontSize   : '0.9rem',
			display    : 'flex',
			alignItems : 'center',
			[theme.breakpoints.down('xs')] :
			{
				padding : '0.5rem 0'
			}
		},
		searchInput : {
			background   : '#999898',
			padding      : '0.5rem 0.7rem',
			borderRadius : '0.5rem',
			color        : 'white',
			textAlign    : 'left',
			border       : 'none',
			width        : '100%',
			marginRight  : '1rem',
			'&:focus'    : {
				outline : 'none'
			}
		},
		searchDateLine : {
			display : 'flex'
		},
		searchDate : {
			display     : 'flex',
			marginRight : '1rem'
		},
		dateInputRoot : {
			width     : '10rem',
			fontSize  : '0.9rem',
			'& input' : {
				padding         : '6px 7px !important',
				backgroundColor : '#FFF !important'
			}
		},
		separator : {
			color    : 'var(--text-color)',
			margin   : '0 0.5rem',
			fontSize : '1rem'
		},
		searchBtn : {
			width           : '6rem',
			borderRadius    : '0.5rem',
			fontSize        : '0.8rem',
			backgroundColor : '#FFDC00',
			color           : '#292929',
			boxShadow       : '2px 2px 4px inset #aaa',
			justifyContent  : 'center',
			alignItems      : 'center',
			display         : 'flex',
			lineHeight      : 1.5,
			whiteSpace      : 'nowrap',
			marginTop       : '10px',
			marginRight     : '0',
			'&:hover'       : {
				backgroundColor : '#FFDC00',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		dateNavigation : {
			display        : 'flex',
			justifyContent : 'flex-start',
			color          : '#292929'
		},
		dateNavigationButtons : {
			display        : 'flex',
			justifyContent : 'space-between',
			marginLeft     : '10px'
		},
		dateNavigationButton : {
			height   : '23px',
			padding  : '0 10px',
			fontSize : '0.9rem',
			color    : '#292929',
			border   : '1px solid #292929'
		},
		dateNavigationCurrent : {
			width     : '150px',
			textAlign : 'center'
		},
		exportButtonWrapper : {
			marginTop  : '-2px',
			marginLeft : '10px',
			width      : '90px'
		},
		exportButtonWrapperZeus : {
			marginTop : '22px',
			width     : '90px'
		},
		exportButton : {
			height   : '23px',
			padding  : '0 10px',
			fontSize : '0.9rem',
			color    : '#292929',
			border   : '1px solid #292929'
		},
		tableBg :
		{
			display  : 'flex',
			position : 'absolute',
			height   : '80%',
			width    : '92%',
			minWidth : 1205,
			zIndex   : '0',
			overflow : 'auto'
		},
		tableBgLine :
		{
			height      : '100%',
			borderRight : '1px solid #ccc'
		},
		headerSection : {
			backgroundColor : '#FFF',
			width           : '100%',
			borderRadius    : '20px',
			padding         : '20px 20px 21px 20px'
		},
		tableData :
		{
			display      : 'flex',
			listStyle    : 'none',
			marginBottom : '0.8rem',
			width        : '100%'
		},
		tableDataBg : {
			display      : 'flex',
			width        : '100%',
			border       : 'none',
			borderRadius : '0.5rem',
			background   : 'transparent',
			alignItems   : 'center',
			zIndex       : 1
		},
		tableDataValues : {
			display      : 'flex',
			width        : '100%',
			border       : 'none',
			borderRadius : '0.5rem',
			background   : 'rgba(0,0,0,0.1)',
			alignItems   : 'center',
			zIndex       : 1
		},
		tableDataValue : {
			paddingTop    : '1.2rem',
			paddingBottom : '1rem',
			margin        : '0 0 0 0',
			color         : '#888',
			textAlign     : 'center',
			fontSize      : '0.8rem'
		},
		commissionPercent : {
			borderRight : '1px dashed rgba(224, 224, 224, 1) !important'
		},
		code :
		{
			width          : '65%',
			paddingLeft    : '0.5%',
			paddingRight   : '0.5%',
			display        : 'flex',
			justifyContent : 'flex-between',
			'& > input'    : {
				backgroundColor : 'white',
				border          : '1px solid #ccc',
				borderRadius    : '0.5rem',
				padding         : '0.3rem 0.5rem',
				boxShadow       : '2px 2px 4px inset #eee',
				color           : '#999898',
				fontSize        : '0.9rem',
				width           : '95%'
			}
		},
		datetime :
		{
			display        : 'flex',
			justifyContent : 'center',
			width          : '17%'
		},
		codeBg : {
			textAlign : 'left'
		},
		datetimeBg : {
			width        : '80%',
			background   : '#999898',
			padding      : '0.2rem 0.3rem',
			borderRadius : '0.3rem',
			color        : 'white',
			textAlign    : 'center',
			height       : '1.5rem'
		},
		center :
		{
			width        : `calc( 100% - ${drawerWidth}px )`,
			height       : 'calc( 100% - 40px )',
			overflow     : 'auto',
			marginTop    : '5px',
			marginBottom : '1%'
		},
		adminButtons :
		{
			width          : '100%',
			height         : '40px',
			display        : 'flex',
			justifyContent : 'end'
		},
		adminBtn :
		{
			background   : '#e26b04',
			border       : '1px solid #e26b04',
			borderRadius : '0.3rem',
			color        : '#fff',
			padding      : '0.1rem 1rem',
			fontSize     : '0.8rem',
			'&:hover' :
			{
				backgroundColor : '#fff',
				color           : '#e26b04',
				opacity         : 0.8
			}
		},
		codeValue : {
			width : 'calc( 100% - 50px )'
		},
		codeBtn :
		{
			width : '50px'
		},
		valueInput : {
			'&:disabled' : {
				backgroundColor : '#F7F9F9'
			}
		},
		drawer : {
			[theme.breakpoints.up('sm')] : {
				width : drawerWidth
			}

		},
		toolbar     : theme.mixins.toolbar,
		drawerPaper : {
			width : drawerWidth
		},
		drawerBox : {
			display        : 'flex',
			flexDirection  : 'column',
			flexShrink     : 0,
			height         : '100%',
			justifyContent : 'space-between'

		},
		selectedItem : {
			backgroundColor : '#ffdc00',
			color           : '#FFF',
			'&:hover' :
			{
				backgroundColor : '#ffdc00',
				color           : '#FFF'
			}
		},
		appBar : {
			backgroundColor : '#ffdc00',
			transition      : theme.transitions.create([ 'margin', 'width' ], {
				easing   : theme.transitions.easing.sharp,
				duration : theme.transitions.duration.leavingScreen
			}),
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		appBarShift : {
			width      : `calc(100% - ${drawerWidth}px)`,
			marginLeft : drawerWidth,
			transition : theme.transitions.create([ 'margin', 'width' ], {
				easing   : theme.transitions.easing.easeOut,
				duration : theme.transitions.duration.enteringScreen
			}),
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		menuButton : {
			marginRight : theme.spacing(2)
		},
		hide : {
			display : 'none'
		},
		drawerHeader : {
			display                      : 'flex',
			alignItems                   : 'center',
			padding                      : theme.spacing(0, 1),
			justifyContent               : 'flex-end',
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		drawerText : {
			fontSize : '0.9rem'
		},
		selectedDrawerItemIcon : {
			color : '#FFF'
		},
		content : {
			flexGrow : 1,
			padding  : theme.spacing(3)
		},
		drawerLogoutBtnSection : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'space-between',
			alignItems     : 'center',
			marginBottom   : '10px'
		},
		drawerLogoutBtn : {
			backgroundColor : '#343532',
			padding         : '3px 15px',
			color           : '#FFF',
			'&:hover' :
			{
				backgroundColor : '#484A46'
			}
		},
		drawerBtmDevider : {
			backgroundColor : 'rgba(0, 0, 0, 0.20)',
			margin          : '10px 0 5px 0',
			width           : '100%'
		},
		loadingIconBox : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		loadingIcon : {
			margin    : '10px 0',
			fontSize  : '2rem',
			color     : '#999898',
			animation : '$blinkEffect 1s ease infinite'
		},
		'@keyframes blinkEffect' : {
			'0%' : {
				opacity : 0
			},
			'50%' : {
				opacity : 0.75
			},
			'100%' : {
				opacity : 1
			}
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiTableCell : {
			root : {
				borderRight : '1px solid rgba(224, 224, 224, 1)'
			}
		}
	}
});

const today = new Date();

const thisYear = today.getFullYear();

const thisMonth = today.getMonth()+1;

let nextMonth = thisMonth+1;

let nextMonthYear = thisYear;

if (nextMonth > 12)
{
	nextMonth = nextMonth - 12;
	nextMonthYear = nextMonthYear + 1;
}

const showNumberHint = (accountIdentifier, numberHint) =>
{
	if (accountIdentifier && numberHint)
	{
		return `${accountIdentifier?.toUpperCase()}********${numberHint}`;
	}
	else
	{
		return '';
	}
};

const showMinutesFromSeconds = (s) =>
{
	if (s === -1)
	{
		return '無制限';
	}
	else if (s)
	{
		return Math.ceil(s/60);
	}
	else
	{
		return 0;
	}
};

const calcMinutesFromSeconds = (s) =>
{
	if (s === -1)
	{
		return null;
	}
	else if (s)
	{
		return Math.ceil(s/60);
	}
	else
	{
		return 0;
	}
};

const includeConsumptionTax = (amount) =>
{

	return Math.floor(amount*(1+CONSUMPTION_TAX));
};

const tenYearsFromNow = dayjs()
	.add(10, 'year')
	.format('YYYY-MM-DDT23:59:59');

const splitItemsLen = 100;

const ConnectUrls = ({
	classes,
	connections,
	setConnections,
	page,
	isZeus
}) =>
{

	const listStartRef = useRef();

	const scrollRef = useRef();

	const [ purchasedAmount, setPurchasedAmount ] = useState(null);

	const [ holesalePurchasedAmount, setHolesalePurchasedAmount ] = useState(null);

	const [ purchasedCount, setPurchasedCount ] = useState(0);

	// filter by page
	const [ connectionsAll, setConnectionsAll ] = useState([]);

	// after searched
	const [ connectionsToShow, setConnectionsToShow ] = useState([]);

	const [ targetDate, setTargetDate ] = useState(
		{
			year          : thisYear,
			month         : thisMonth,
			nextMonthYear : nextMonthYear,
			nextMonth     : nextMonth
		}
	);

	const [ searchCustomerId, setSearchCustomerId ] = useState('');

	const [ searchEmail, setSearchEmail ] = useState('');

	const [ searchActiveFromDate, setSearchActiveFromDate ] = useState('');

	const [ searchExpiryTimestampDate, setSearchExpiryTimestampDate ] = useState('');

	const [ searchStatus, setSearchStatus ] = useState({
		searched : false,
		length   : null
	});

	const [ connectionExpirylDialog, setConnectionExpiryDialog ] = useState({
		show       : false,
		type       : '',
		connection : {}
	});

	// split urls list to show
	const [ nextStartItemIndex, setNextStartItemIndex ] = useState(splitItemsLen);

	useEffect(() =>
	{

		const date = dayjs({
			y : targetDate.year,
			M : targetDate.month -1,
			d : 1
		});

		let salesAmountVal = 0;

		let holesaleAmountVal = 0;

		let purchaseCount = 0;

		const startMonth = date.startOf('month').valueOf();

		const endMonth = date.endOf('month').valueOf();

		const items = [];

		let targetConnections = [];

		const currentTimestamp = Date.now();

		if (page === 'list-active') // 現在有効のみ
		{

			targetConnections = connections.filter((itm) =>
				itm.activeFrom <= currentTimestamp && itm.expiryTimestamp >= currentTimestamp
			);
		}
		else if (page === 'list-before-active') // 有効化前飲み
		{

			targetConnections = connections.filter((itm) =>
				itm.activeFrom > currentTimestamp
			);
		}
		else // list-all すべて
		{
			targetConnections = [ ...connections ];
		}

		if (isZeus)
		{
			targetConnections.forEach((item) =>
			{
				if (item.orderCanceled)
				{
					items.push({
						...item,
						selected       : item.selected ? item.selected : false,
						salesAmount    : 0,
						holesaleAmount : 0,
						status :
							item.expiryTimestamp < currentTimestamp ? 'ended'
								: item.activeFrom <= currentTimestamp ? 'active'
									: 'before-active'
					});
				}
				else
				{
					items.push({
						...item,
						selected : item.selected ? item.selected : false,
						status :
							item.expiryTimestamp < currentTimestamp ? 'ended'
								: item.activeFrom <= currentTimestamp ? 'active'
									: 'before-active'
					});
					purchaseCount += 1;
					salesAmountVal += includeConsumptionTax(item.salesAmount);
					holesaleAmountVal += includeConsumptionTax(item.holesaleAmount);
				}
			});
		}
		else
		{
			targetConnections.forEach((item) =>
			{
				if (item.createdAt >= startMonth && item.createdAt <= endMonth)
				{

					if (item.orderCanceled)
					{
						items.push({
							...item,
							selected       : item.selected ? item.selected : false,
							salesAmount    : 0,
							holesaleAmount : 0
						});
					}
					else
					{
						items.push({
							...item,
							selected : item.selected ? item.selected : false
						});
						purchaseCount += 1;
						salesAmountVal += includeConsumptionTax(item.salesAmount);
						holesaleAmountVal += includeConsumptionTax(item.holesaleAmount);
					}
				}
			});

		}

		setPurchasedAmount(salesAmountVal);
		setPurchasedCount(purchaseCount);
		setHolesalePurchasedAmount(holesaleAmountVal);

		if (!isZeus)
		{
			items.sort((a, b) =>
			{
				if (a.expiryTimestamp < b.expiryTimestamp) { return -1; }
				if (a.expiryTimestamp > b.expiryTimestamp) { return 1; }

				return 0;
			});

			items.sort((a, b) =>
			{
				if (a.activeFrom < b.activeFrom) { return 1; }
				if (a.activeFrom > b.activeFrom) { return -1; }

				return 0;
			});
		}
		else
		{
			items.sort((a, b) =>
			{
				if (a.salesAmount < b.salesAmount) { return -1; }
				if (a.salesAmount > b.salesAmount) { return 1; }

				return 0;
			});

			items.sort((a, b) =>
			{
				if (a.createdAt < b.createdAt) { return 1; }
				if (a.createdAt > b.createdAt) { return -1; }

				return 0;
			});

		}

		setNextStartItemIndex(splitItemsLen);

		setConnectionsAll([ ...items ]);

		setConnectionsToShow([ ...items.slice(0, splitItemsLen) ]);

		setSearchCustomerId('');
		setSearchEmail('');
		setSearchActiveFromDate('');
		setSearchExpiryTimestampDate('');

		setSearchStatus({ searched: false, length: items.length });

	}, [ connections, targetDate, page, isZeus ]);

	const showDateTime = (timestamp) =>
	{
		if (!timestamp)
		{
			return '';
		}
		else
		{
			return dayjs(timestamp).format('YYYY.MM.DD HH:mm');
		}
	};

	const showDate = (timestamp) =>
	{
		if (!timestamp)
		{
			return '';
		}
		else
		{
			return dayjs(timestamp).format('YYYY.MM.DD');
		}
	};

	const togglePurchasedConnectionFiltered = (itemId) =>
	{
		setConnections((state) =>
		{
			const items = [ ...state ];

			const targetIndex = items.findIndex((item) => item.id === itemId);

			if (targetIndex !== -1)
			{
				items[targetIndex].selected = !items[targetIndex].selected;
			}

			return [ ...items ];
		});
	};

	const changeTargetMonth = (val) =>
	{
		setTargetDate((state) =>
		{
			let newMonth = state.month+val;

			let newYear = state.year;

			if (newMonth < 1)
			{
				newYear = newYear - 1;
				newMonth = 12;
			}
			else if (newMonth > 12)
			{
				newYear = newYear + 1;
				newMonth = 1;
			}

			let newNextMonthYear = newYear;

			let newNextMonth = newMonth + 1;

			if (newNextMonth > 12)
			{
				newNextMonthYear = newNextMonthYear + 1;
				newNextMonth = 1;
			}

			return {
				year          : newYear,
				month         : newMonth,
				nextMonthYear : newNextMonthYear,
				nextMonth     : newNextMonth
			};
		});

	};

	const downloadFile = ({ data, fileName, fileType }) =>
	{
		const bom = new Uint8Array([ 0xEF, 0xBB, 0xBF ]);
		const blob = new Blob([ bom, data ], { type: fileType });
		const a = document.createElement('a');

		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);

		const clickEvent = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });

		a.dispatchEvent(clickEvent);
		a.remove();
	};

	const exportPurchaseToCsv = () =>
	{
		const headers = [ '利用開始,接続キー,メールアドレス,顧客ID,利用終了,利用分数,御社販売価格, 卸売価格' ];

		const body = [];

		connectionsAll.forEach((d) =>
		{
			const { createdAt,
				accountParamKey,
				numberHint,
				email,
				customerId,
				expiryTimestamp,
				duration,
				salesAmount,
				holesaleAmount,
				orderCanceled
			} = d;

			body.push([ showDateTime(createdAt), orderCanceled ? '購入キャンセル' : showNumberHint(accountParamKey, numberHint), email, customerId, expiryTimestamp ? showDateTime(expiryTimestamp) : '', calcMinutesFromSeconds(duration), salesAmount, holesaleAmount ].join(','));
		});

		downloadFile({
			data     : [ ...headers, ...body ].join('\n'),
			fileName : 'purchase.csv',
			fileType : 'text/csv'
		});
	};

	const updateConnectionsToShow = (sliceEnd, filterBySearchCond) =>
	{
		let result = [];

		if (filterBySearchCond)
		{
			const activeFromTimestamp = searchActiveFromDate
				? dayjs(`${searchActiveFromDate} 00:00:00.000`)
					.tz('Asia/Tokyo')
					.valueOf()
				: null;

			const expiryTimestamp = searchExpiryTimestampDate
				? dayjs(`${searchExpiryTimestampDate} 23:59:59.999`)
					.tz('Asia/Tokyo')
					.valueOf()
				: null;

			result = connectionsAll.filter((con) =>
			{

				if (searchCustomerId && con.customerId !== searchCustomerId)
				{
					return false;
				}

				if (searchEmail && con.email !== searchEmail)
				{
					return false;
				}

				if (activeFromTimestamp &&
					con.activeFrom <= activeFromTimestamp
				)
				{

					return false;
				}

				if (
					expiryTimestamp
					&& con.expiryTimestamp <= expiryTimestamp
				)
				{
					return false;
				}

				return true;
			});
		}
		else
		{
			result = connectionsAll;
		}

		// 検索前と検索後でlengthが一致=>検索してない
		if (result.length === connectionsAll.length)
		{
			setSearchStatus({ searched: false, length: connectionsAll.length });
		}
		else
		{
			setSearchStatus({ searched: true, length: result.length });
		}

		// for scroll
		setNextStartItemIndex(sliceEnd);

		setConnectionsToShow(result.slice(0, sliceEnd));

	};

	const handleChangeSearchCustomerId = (e) =>
	{
		setSearchCustomerId(e.target.value ? e.target.value.trim() : '');
	};

	const handleChangeSearchEmail = (e) =>
	{
		setSearchEmail(e.target.value ? e.target.value.trim() : '');
	};

	const handleChangeSearchActiveFromDate = (e) =>
	{
		setSearchActiveFromDate(e.target.value);
	};

	const handleChangeSearchExpiryTimestampDate = (e) =>
	{
		setSearchExpiryTimestampDate(e.target.value);
	};

	const showExpiryDialog = (connectionData) =>
	{

		setConnectionExpiryDialog({
			show       : true,
			type       : 'connection-expiry',
			connection : {
				...connectionData
			}
		});
	};

	const closeExpiryDialog = () =>
	{
		setConnectionExpiryDialog({
			show       : false,
			type       : '',
			connection : {}
		});
	};

	useEffect(() =>
	{
		// リセット => スクロールを最初に戻す
		if (listStartRef.current && nextStartItemIndex === splitItemsLen)
		{
			try
			{
				listStartRef.current.scrollIntoView({});
			}
			catch (e)
			{
				// do nothing
				// eslint-disable-next-line no-console
				console.log('Scroll error:', e);
			}
		}
	}, [ nextStartItemIndex ]);

	const handleScroll = () =>
	{
		if (scrollRef.current)
		{
			try
			{
				const scrollHeight = Math.round(scrollRef.current.scrollHeight);

				const scrollAmount = Math.round(scrollRef.current.scrollTop);

				const elementHeight = Math.round(scrollRef.current.clientHeight);

				// 最初の取得は初期値分追加
				let additionalTimes = 1;

				// 2回目以降は2倍追加
				if (nextStartItemIndex > splitItemsLen)
				{
					additionalTimes = 2;
				}

				// scroll reaches end
				if (scrollHeight - 35 < scrollAmount + elementHeight)
				{
					// add new item
					updateConnectionsToShow(
						nextStartItemIndex+splitItemsLen*additionalTimes,
						searchStatus.searched
					);
				}
			}
			catch
			{
				// do nothing
			}
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<ExpiryDialog
				show={connectionExpirylDialog.show}
				type={connectionExpirylDialog.type}
				target={connectionExpirylDialog.connection}
				closeMethod={closeExpiryDialog}
			/>
			<Box className={classes.monthlyWrapper}>
				<Box className={classes.root}>
					<ConnectUserCount
						page={page}
						isZeus={isZeus}
						targetDate={targetDate}
						purchasedCount={purchasedCount}
						purchasedAmount={purchasedAmount}
						holesalePurchasedAmount={holesalePurchasedAmount}
						count={connectionsAll ? connectionsAll.length : 0}
					/>
					{ isZeus ?
						<Box className={classes.navigationSection}>
							<Box className={classes.exportButtonWrapperZeus}>
								<Button
									className={classes.exportButton}
									onClick={exportPurchaseToCsv}
								>
									CSV出力
								</Button>
							</Box>
							<Box className={classes.searchArea}>
								<Box className={`${classes.searchGroup} ${classes.searchGroupInput}`}>
									<p className={classes.searchTitle}>顧客ID</p>
									<input
										type='text'
										value={searchCustomerId}
										className={classes.searchInput}
										onChange={(e) => handleChangeSearchCustomerId(e)}
									/>
								</Box>
								<Box className={`${classes.searchGroup} ${classes.searchGroupInput}`}>
									<p className={classes.searchTitle}>メールアドレス</p>
									<input
										type='text'
										value={searchEmail}
										className={classes.searchInput}
										onChange={(e) => handleChangeSearchEmail(e)}
									/>
								</Box>
								<Box className={`${classes.searchGroup} ${classes.searchGroupDate}`}>
									<p className={classes.searchTitle}>利用期間</p>
									<Box className={classes.searchDateLine}>
										<Box className={classes.searchDate}>
											<FormControl variant='outlined'>
												<TextField
													variant='outlined'
													type='date'
													defaultValue={searchActiveFromDate}
													key={searchActiveFromDate}
													onSelect={(e) => handleChangeSearchActiveFromDate(e)}
													onBlur={(e) => handleChangeSearchActiveFromDate(e)}
													InputProps={{ inputProps: { min: '2024-01-01T00:00', max: tenYearsFromNow } }}
													classes={{
														root : classes.dateInputRoot
													}}
												/>
											</FormControl>

											<Typography variant='h6' className={classes.separator}>
												~
											</Typography>

											<FormControl variant='outlined'>
												<TextField
													variant='outlined'
													type='date'
													defaultValue={searchExpiryTimestampDate}
													key={searchExpiryTimestampDate}
													onSelect={(e) => handleChangeSearchExpiryTimestampDate(e)}
													onBlur={(e) => handleChangeSearchExpiryTimestampDate(e)}
													InputProps={{ inputProps: { min: '2024-01-01T00:00', max: tenYearsFromNow } }}
													classes={{
														root : classes.dateInputRoot
													}}
												/>
											</FormControl>
										</Box>
									</Box>
								</Box>
								<Box className={classes.searchGroupSearch}>
									<Button className={classes.searchBtn}
										onClick={() => updateConnectionsToShow(splitItemsLen, true)}
									>
										<p >検&nbsp;&nbsp;索</p>
									</Button>
								</Box>
							</Box>
						</Box>
						:
						<Box className={classes.navigationSection}>
							<Box className={classes.dateNavigation}>
								<Box className={classes.dateNavigationButtons}>
									<Button
										className={classes.dateNavigationButton}
										startIcon={<NavigateBeforeIcon />}
										onClick={() => changeTargetMonth(-1)}
									>
										前月
									</Button>
									<Box className={classes.dateNavigationCurrent}>
										{targetDate.year}年{targetDate.month}月分
									</Box>
									{ (thisYear !== targetDate?.year || thisMonth !== targetDate?.month) &&
									<Button
										className={classes.dateNavigationButton}
										startIcon={<NavigateNextIcon />}
										onClick={() => changeTargetMonth(1)}
									>
										翌月
									</Button>
									}
								</Box>
							</Box>

							<Box className={classes.exportButtonWrapper}>
								<Button
									className={classes.exportButton}
									onClick={exportPurchaseToCsv}
								>
									CSV出力
								</Button>
							</Box>
						</Box>
					}

					<Box className={classes.tableSection} ref={scrollRef} onScroll={handleScroll}>
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label='simple table'>
								<TableHead>
									{ isZeus ?
										<TableRow ref={listStartRef}>
											<TableCell> </TableCell>
											<TableCell align='center'>顧客ID</TableCell>
											<TableCell align='center'>メールアドレス</TableCell>
											<TableCell align='center'>利用開始日</TableCell>
											<TableCell align='center'>利用終了日</TableCell>
											<TableCell align='center'>接続キー</TableCell>
											<TableCell align='center'>ステータス</TableCell>
											<TableCell align='center'>操作</TableCell>
										</TableRow>
										:
										<TableRow ref={listStartRef}>
											<TableCell> </TableCell>
											<TableCell align='center'>顧客ID</TableCell>
											<TableCell align='center'>メールアドレス</TableCell>
											<TableCell align='center'>利用開始</TableCell>
											<TableCell align='center'>利用終了</TableCell>
											<TableCell align='center'>接続キー</TableCell>
											<TableCell align='center'>利用分数</TableCell>
											<TableCell align='center'>定価</TableCell>
											<TableCell align='center'>御社購入価格</TableCell>
										</TableRow>
									}
								</TableHead>
								<TableBody>
									{connectionsToShow.map((row) => (
										<TableRow key={row.id}>
											<TableCell padding='checkbox' align='center'>
												<Checkbox
													checked={row.selected}
													inputProps={{ 'aria-labelledby': `checkBox-${row.id}` }}
													onClick={() => togglePurchasedConnectionFiltered(row.id)}
												/>
											</TableCell>
											<TableCell align='center'>{row.customerId}</TableCell>
											<TableCell align='center'>{row.email}</TableCell>
											<TableCell align='center'>{
												isZeus ? showDate(row.activeFrom)
													: showDateTime(row.activeFrom)
											}
											</TableCell>
											{
												row.expiryTimestamp ?
													<TableCell align='center'>{
														isZeus ?
															showDate(row.expiryTimestamp)
															: showDateTime(row.expiryTimestamp)
													}
													</TableCell>
													:
													<TableCell align='center'>-</TableCell>
											}
											<TableCell align='center'>{ row.orderCanceled ? '購入キャンセル' : row.connectionKey }</TableCell>
											{ !isZeus &&
												<TableCell align='center'>{showMinutesFromSeconds(row.duration)}</TableCell>
											}
											{
												isZeus ? null
													: row.usageBasedBilling && !row.salesAmount ?
														<TableCell align='center'>従量制</TableCell>
														:
														<TableCell align='center'>{showTaxIncludedAmount(row.salesAmount)}円</TableCell>
											}
											{
												isZeus ? null
													:row.usageBasedBilling && !row.holesaleAmount ?
														<TableCell align='center'>従量制</TableCell>
														:
														<TableCell align='center'>{showTaxIncludedAmount(row.holesaleAmount)}円</TableCell>
											}

											{ isZeus &&
											<TableCell align='center'>
												<Box className={classes.searchStatusBox}>
													{
														row.status === 'active' ?
															<Box className={`${classes.searchStatus} ${classes.searchStatusActive}`}>
																稼働中
															</Box>
															: row.status === 'ended' ?
																<Box className={`${classes.searchStatus} ${classes.searchStatusEnded}`}>
																	終了
																</Box>
																:
																<Box className={`${classes.searchStatus} ${classes.searchStatusBeforeActive}`}>
																	スタンバイ
																</Box>
													}
												</Box>
											</TableCell>}
											{ isZeus && <TableCell align='center'>
												<Button
													variant='contained'
													color='primary'
													onClick={
														() => showExpiryDialog(row)
													}
													className={`${classes.tableBtn} ${classes.expiryBtn}`}
												>
													期限変更
												</Button>
											</TableCell>}
										</TableRow>
									))}
								</TableBody>
							</Table>
							{
								connectionsAll.length > splitItemsLen
											&& (
												(!searchStatus.searched
												&& connectionsAll.length > connectionsToShow.length)
												||
												(searchStatus.searched
												&& searchStatus.length > connectionsToShow.length)
											)
											&&
											<Box className={classes.loadingIconBox}>
												<CachedIcon
													className={classes.loadingIcon}
													onClick={
														updateConnectionsToShow(
															nextStartItemIndex+splitItemsLen,
															searchStatus.searched
														)
													}
												/>
											</Box>

							}
						</TableContainer>
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>
	);

};

ConnectUrls.propTypes =
{
	classes        : PropTypes.object.isRequired,
	connections    : PropTypes.array.isRequired,
	setConnections : PropTypes.func.isRequired,
	page           : PropTypes.string.isRequired,
	isZeus         : PropTypes.bool.isRequired
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectUrls)));