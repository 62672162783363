import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { ChevronLeft, Home } from '@material-ui/icons';

// locale
import { FormattedMessage } from 'react-intl';

import Logo from '../../../../images/connect/zeus_logo.svg';

const styles = () => ({
	headerContainer : {
		width           : '100%',
		height          : '80px',
		backgroundColor : '#21C0B2'
	},
	contentHeaderContainer : {
		display    : 'flex',
		alignItems : 'center',
		gap        : '50px',
		padding    : '0 20px',
		height     : '100%',
		color      : '#FFF'
	},
	logo : {
		width     : '80px',
		height    : '80px',
		objectFit : 'contain',
		cursor    : 'pointer'
	},
	navigationContainer : {
		display        : 'flex',
		justifyContent : 'space-between',
		alignItems     : 'center'
	},
	navigationItem : {
		margin   : '0 10px',
		cursor   : 'pointer',
		fontSize : '16px'
	},
	backButton : {
		display : 'none'
	},
	homeButton : {
		display : 'none'
	},
	'@media (max-width: 720px)' : {
		contentHeaderContainer : {
			padding        : '0 10px',
			justifyContent : 'space-between'
		},
		containerLanding : {
			justifyContent : 'center !important'
		},
		navigationContainer : {
			display : 'none'
		},
		backButton : {
			display         : 'block',
			border          : 'none',
			backgroundColor : 'none',
			cursor          : 'pointer'
		},
		backIcon : {
			color  : '#FFF',
			width  : '36px',
			height : '36px'
		},
		homeButton : {
			display : 'block'
		},
		homeIcon : {
			color  : '#FFF',
			width  : '36px',
			height : '36px',
			cursor : 'pointer'
		}
	}
});

const Header = ({ classes, page, setPage }) =>
{
	return (
		<div className={classes.headerContainer}>
			{ page === 'landing' ?
				<div className={`${classes.contentHeaderContainer} ${classes.containerLanding}`}>
					<img src={Logo} alt='logo' className={classes.logo} />
					<div className={classes.navigationContainer}>
						<div className={classes.navigationItem} onClick={() => setPage('landing')}>
							<FormattedMessage
								id='connect.home'
								defaultMessage='Home'
							/>
						</div>
						<div className={classes.navigationItem} onClick={() => setPage('history')}>
							<FormattedMessage
								id='connect.TranslationHistory'
								defaultMessage='Translation History'
							/>
						</div>
					</div>
				</div>
				:
				<div className={classes.contentHeaderContainer}>
					<div className={classes.backButton} onClick={() => setPage('landing')} >
						<ChevronLeft className={classes.backIcon} />
					</div>
					<img src={Logo} alt='logo' className={classes.logo} />
					<div className={classes.navigationContainer}>
						<div className={classes.navigationItem} onClick={() => setPage('landing')}>
							<FormattedMessage
								id='connect.home'
								defaultMessage='Home'
							/>
						</div>
						<div className={classes.navigationItem} onClick={() => setPage('history')}>
							<FormattedMessage
								id='connect.TranslationHistory'
								defaultMessage='Translation History'
							/>
						</div>
					</div>
					<div className={classes.homeButton} onClick={() => setPage('landing')}>
						<Home className={classes.homeIcon} />
					</div>
				</div>
			}
		</div>
	);
};

export default withStyles(styles)(Header);
