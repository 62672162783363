import { getYearMonthDay, getYearMonth } from '../../utils/formatDate';
import { CALL_STATUS_CATCH, CALL_STATUS_FINISHED, ROOM_ONLINE, ROOM_CALLING } from '../../../../../../../const';

const filterCalls = (funcGetDate, dateTime, calls) =>
{
	const condDate = funcGetDate(dateTime);

	const filteredCalls = calls.filter((item) =>
	{
		const date = funcGetDate(item.timestamp);

		return condDate === date;
	});

	return filteredCalls;
};

const filterRoomSnapshots = (funcGetDate, dateTime, roomsSnapshots) =>
{
	const condDate = funcGetDate(dateTime);

	const filteredSnapshots = roomsSnapshots.filter((item) =>
	{

		const date = funcGetDate(item.timestamp);

		return condDate === date;
	});

	return filteredSnapshots;
};

// filter data operator by Year month
export function getYearMonthData(
	calls,
	hostRecievedCalls,
	dateYearMonth,
	roomsSnapshots,
	host
)
{
	const filteredCalls = filterCalls(getYearMonth, dateYearMonth, calls);

	const filteredHostRecievedCalls
			= filterCalls(getYearMonth, dateYearMonth, hostRecievedCalls);

	const filteredRoomSnapshots
			= filterRoomSnapshots(getYearMonth, dateYearMonth, roomsSnapshots);

	// number call coming
	const numberOfIncomingCalls = filteredCalls.reduce((accumulator, item) =>
	{
		if (item.calls)
		{
			for (const c of item.calls)
			{
				if (c.hostId === host.id)
				{
					accumulator++;

					break;
				}
			}
		}

		return accumulator;
	}, 0);

	// number call received
	const numberOfCallsReceived = filteredHostRecievedCalls.reduce((accumulator, item) =>
	{

		if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
		{
			accumulator++;
		}

		return accumulator;
	}, 0);

	// total talk time
	const totalTalkSeconds = filteredHostRecievedCalls.reduce((accumulator, item) =>
	{
		accumulator += item.duration;

		return accumulator;
	}, 0);

	const totalTalkTime = Math.round((totalTalkSeconds/60)*100)/100;

	// average talk time
	const averageTalkSeconds = numberOfCallsReceived > 0
		? Math.round((totalTalkSeconds / numberOfCallsReceived) * 100) / 100 : 0;

	const averageTalkTime = Math.round((averageTalkSeconds/60)*100)/100;

	// call received rate
	let callReceivedRate = 0;

	if (numberOfIncomingCalls > 0)
	{
		const resultRate = (numberOfCallsReceived / numberOfIncomingCalls) * 100;
		const roundedResultRate = resultRate.toFixed(2);

		if (roundedResultRate.indexOf('.') !== -1 && parseFloat(roundedResultRate) % 1 === 0)
		{
			callReceivedRate = parseInt(roundedResultRate, 10);
		}
		else
		{
			callReceivedRate = parseFloat(roundedResultRate);
		}
	}

	const waitingRoomSnapshots = filteredRoomSnapshots.filter((item) =>
	{
		let activeOperatorRoomFound = false;

		if (item.rooms)
		{
			for (const rm of item.rooms)
			{
				if ((rm.status === ROOM_ONLINE || rm.status === ROOM_CALLING)
						&& rm.hostId === host.id)
				{
					activeOperatorRoomFound = true;
					break;
				}
			}
		}

		return activeOperatorRoomFound;
	});

	const timeWaiting = waitingRoomSnapshots.reduce((total, item) =>
	{

		total += item.intervalMinute ? item.intervalMinute : 5;

		return total;

	}, 0);

	// total operating time
	const upTime = Math.round((totalTalkTime + timeWaiting) * 100) / 100;

	return {
		numberOfIncomingCalls,
		numberOfCallsReceived,
		callReceivedRate,
		totalTalkTime,
		averageTalkTime,
		upTime
	};
}

// filter data operator by day month
export function getMonthDayData(
	calls,
	hostRecievedCalls,
	dateMonthDay,
	roomsSnapshots,
	host
)
{
	const filteredCalls = filterCalls(getYearMonthDay, dateMonthDay, calls);

	const filteredHostRecievedCalls
			= filterCalls(getYearMonthDay, dateMonthDay, hostRecievedCalls);

	const filteredRoomSnapshots
			= filterRoomSnapshots(getYearMonthDay, dateMonthDay, roomsSnapshots);

	// number call coming
	const numberOfIncomingCalls = filteredCalls.reduce((accumulator, item) =>
	{

		if (item.calls)
		{
			for (const c of item.calls)
			{
				if (c.hostId === host.id)
				{
					accumulator++;

					break;
				}
			}
		}

		return accumulator;
	}, 0);

	// number call received
	const numberOfCallsReceived = filteredHostRecievedCalls.reduce((accumulator, item) =>
	{

		if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
		{
			accumulator++;
		}

		return accumulator;
	}, 0);

	// total talk time
	const totalTalkSeconds = filteredHostRecievedCalls.reduce((accumulator, item) =>
	{

		accumulator += item.duration;

		return accumulator;
	}, 0);

	const totalTalkTime = Math.round((totalTalkSeconds/60)*100)/100;

	// average talk time
	const averageTalkSeconds = numberOfCallsReceived > 0
		? Math.round((totalTalkSeconds / numberOfCallsReceived) * 100) / 100 : 0;

	const averageTalkTime = Math.round((averageTalkSeconds/60)*100)/100;

	// call received date
	let callReceivedRate = 0;

	if (numberOfIncomingCalls > 0)
	{
		const resultRate = (numberOfCallsReceived / numberOfIncomingCalls) * 100;
		const roundedResultRate = resultRate.toFixed(2);

		if (roundedResultRate.indexOf('.') !== -1 && parseFloat(roundedResultRate) % 1 === 0)
		{
			callReceivedRate = parseInt(roundedResultRate, 10);
		}
		else
		{
			callReceivedRate = parseFloat(roundedResultRate);
		}
	}

	const waitingRoomSnapshots = filteredRoomSnapshots.filter((item) =>
	{
		let activeOperatorRoomFound = false;

		if (item.rooms)
		{
			for (const rm of item.rooms)
			{
				if ((rm.status === ROOM_ONLINE || rm.status === ROOM_CALLING)
						&& rm.hostId === host.id)
				{
					activeOperatorRoomFound = true;
					break;
				}
			}
		}

		return activeOperatorRoomFound;
	});

	const timeWaiting = waitingRoomSnapshots.reduce((total, item) =>
	{

		total += item.intervalMinute ? item.intervalMinute : 5;

		return total;

	}, 0);

	// total operating time
	const upTime = Math.round((totalTalkTime + timeWaiting) * 100) / 100;

	return {
		numberOfIncomingCalls,
		numberOfCallsReceived,
		callReceivedRate,
		totalTalkTime,
		averageTalkTime,
		upTime
	};
}
